<template>
  <div id="user-list">
    <!-- list filters -->
    <v-card>
      <!-- table -->
      <v-data-table
        :headers="orderReportsColumns"
        :items="items"
        :server-items-length="serverItemsLength"
        :loading="loading"
        :options.sync="tempOptions"
      >
        <template #[`item.status`]="{item}">
          <span>
            {{ item.report.status }}
          </span>
          <v-progress-circular
            v-if="item.report.status === 'in_progress'"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </template>

        <template #[`item.report_from_date`]="{item}">
          <span>
            {{ item.report.report_from_date | dateSimple }}
          </span>
        </template>

        <template #[`item.report_to_date`]="{item}">
          <span>
            {{ item.report.report_to_date | dateSimple }}
          </span>
        </template>

        <template #[`item.subtotal`]="{item}">
          <span>
            {{ item.subtotal | money }}
          </span>
        </template>

        <!-- <template #[`item.total`]="{item}">
          <span>
            {{ item.total | money }}
          </span>
        </template> -->

        <template #[`item.channel`]="{item}">
          <span v-if="item.channel">{{ item.channel.code }}</span>
          <span v-else>All</span>
        </template>

        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                @click="downloadReport(item)"
              >
                <v-list-item-title>
                  <span>Download Report (xlsx)</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>

            <v-list>
              <v-list-item
                @click="deleteReport(item)"
              >
                <v-list-item-title>
                  <span>Delete Report</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { t } from '@/plugins/i18n'
import { mdiDotsVertical } from '@mdi/js'

import { useConfirm, useNotifyErrors } from '@/composables'
import { dateSimple, money } from '@core/utils/filter'
import { ref, watch } from '@vue/composition-api'
import useChannelOrderReports from '../useChannelOrderReports'

export default {
  filters: { money, dateSimple },

  props: {
    items: { type: Array, required: true },
    serverItemsLength: { type: Number, required: true },
    loading: { type: Boolean, required: true },
    options: { type: Object, required: true },
  },

  setup(props, { emit }) {
    const { orderReportsColumns, downloadOrderReport, deleteOrderReport } = useChannelOrderReports()

    const xlsxBlobUrl = ref(null)

    const downloadReport = report => {
      if (report.report.status !== 'completed') {
        return useNotifyErrors({
          title: 'Try again after at least 1 minute',
          content: 'The Report generation is not completed yet.',
        })
      }

      downloadOrderReport(report.id).then(({ data }) => {
        xlsxBlobUrl.value = window.URL.createObjectURL(new Blob([data], { type: 'application/vnd.ms-excel' }))
        window.open(xlsxBlobUrl.value)
      })
    }

    const deleteReport = async report => {
      await useConfirm({
        title: 'Confirm remove report?',
        content: 'This action cannot be reversed',
      })
      await deleteOrderReport(report.id).then(() => emit('updated'))
    }

    const tempOptions = ref({ ...props.options })

    watch(tempOptions, () => {
      emit('update:options', tempOptions.value)
    })

    return {
      t,
      tempOptions,

      orderReportsColumns,
      downloadReport,
      deleteReport,

      // icons
      icons: {
        mdiDotsVertical,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
