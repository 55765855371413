import { now } from '@/@core/utils/filter'
import { deleteOrderReport, downloadOrderReport, fetchOrderReport, fetchOrderReports, storeOrderReport } from '@/api/reporting'
import { useNotifyErrors, useNotifySuccess, useTableOptions } from '@/composables'
import chStore from '@/modules/channel/store'
import { computed, onMounted, ref, watch } from '@vue/composition-api'

const cached = []
export default function useChannelOrderReports() {
  const extraReportOptions = [
    {
      title: 'Last Generated Report',
      value: {
        latest: 1,
        take: 1,
      },
    },
    {
      title: 'All Report',
      value: {},
    },
  ]
  const extraReportFilter = ref(extraReportOptions[0].value)
  const orderReports = ref([])
  const orderReportsTotal = ref(0)
  const orderReportsColumns = [
    { text: 'STATUS', value: 'status', sortable: false },
    { text: 'FROM', value: 'report_from_date', sortable: false },
    { text: 'TO', value: 'report_to_date', sortable: false },
    { text: 'Order count', value: 'order_count' },
    { text: 'Item count', value: 'sales_count' },
    { text: 'Items worth', value: 'subtotal' },

    // { text: 'Cash Total', value: 'total' },
    { text: 'CHANNEL', value: 'channel', sortable: false },
    { text: 'ACTIONS', value: 'actions', sortable: false },
  ]

  const loading = ref(false)

  const options = ref(
    cached[0] || {
      sortBy: ['created_at'],
      sortDesc: [true],
      itemsPerPage: 10,
      page: 1,
    },
  )
  const form = ref(
    cached[1] || {
      created_at_from: now().subtract(6, 'day').startOf('day').format('YYYY-MM-DD'),
      created_at_to: now().subtract(1, 'day').endOf('day').format('YYYY-MM-DD'),
    },
  )

  const channelId = computed(() => chStore.state.channel?.id || undefined)

  const reportFilter = computed(() => ({
    channel_id: channelId.value,
    report_from_date: now(form.value.created_at_from).startOf('day').toString(),
    report_to_date: now(form.value.created_at_to).endOf('day').toString(),
  }))

  const loadOrderReports = () =>
    fetchOrderReports(
      useTableOptions(options.value, {
        ...reportFilter.value,
        ...extraReportFilter.value,
      }),
    ).then(({ data }) => {
      orderReports.value = data.data.records
      orderReportsTotal.value = data.data.pagination.total
    })

  const reloadAll = async () => {
    loading.value = true
    options.value.page = 1
    try {
      await loadOrderReports()
    } catch (err) {
      useNotifyErrors(err)
    }
    loading.value = false
  }

  const cachable = [options, form, channelId]

  const cache = () => {
    cachable.forEach((variable, index) => {
      cached[index] = variable.value
    })
  }

  watch(cachable, async () => {
    const newPage = options.value.page

    const hasDiff = cachable.find((variable, index) => index > 0 && cached[index] !== variable.value)

    if (newPage >= 2 && hasDiff) {
      options.value.page = 1
    }

    // loading.value = true
    // await loadOrderReports()
    // loading.value = false
    cache()
  })

  onMounted(() => {
    reloadAll()
  })

  const attemptDownloadReport = async reportable => {
    const {
      data: { data: newReportable },
    } = await fetchOrderReport(reportable.id)
    const { report } = newReportable

    if (report && report.status === 'completed') {
      // downloadOrderReport(reportable.id) // popup blocked =/
      await loadOrderReports()
      useNotifySuccess({
        content: `Your order report (${report.report_from_date} - ${report.report_to_date}) is ready. You may download it now. And make browser "allow popup" for "auto downloading" in the future.`,
        timeout: 10000,
      })
      downloadOrderReport(reportable.id)
    } else {
      setTimeout(() => attemptDownloadReport(reportable), 5000)
    }
  }

  const generateOrderReport = async () => {
    loading.value = true
    const {
      data: { data: reportable },
    } = await storeOrderReport({
      channel_id: channelId.value,
      created_at_from: now(form.value.created_at_from).startOf('day').toString(),
      created_at_to: now(form.value.created_at_to).endOf('day').toString(),
    })

    await loadOrderReports()

    attemptDownloadReport(reportable)
    loading.value = false
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    extraReportOptions,
    extraReportFilter,

    orderReports,
    orderReportsTotal,
    orderReportsColumns,
    downloadOrderReport,
    deleteOrderReport,

    loading,
    form,
    options,
    reloadAll,
    generateOrderReport,
  }
}
